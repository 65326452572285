import { useQuery } from 'react-query'

import apiAdmin from '../../../../../services/apiAdmin'

const useObtendoMensagensAtendimentos = (idProtocolo) => {
  return useQuery(
    'atendimento_useObtendoMensagensAtendimentos',
    async () => {
      const response = await apiAdmin.get(`/v1/api/admin/atendimento/obtendo/mensagens/${idProtocolo}`)

      if (response.status === 200) {
        return response?.data
      }
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 5000,
      refetchInterval: 5000,
      enabled: !!idProtocolo
    }
  )
}

export { useObtendoMensagensAtendimentos }
