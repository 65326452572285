/* eslint-disable react/prop-types */
import React, { useState } from 'react'

import { Card, Text, useMediaQuery, Divider, VStack, HStack, useDisclosure, Box } from '@chakra-ui/react'

import { useGetDadosDaProposta } from '../hooks/useGetDadosDaProposta'
import ConfirmacaoProposta from './ConfirmacaoProposta'
import DialogoProposta from './DialogoProposta'
import Logo from './Logo'
import PropostaFgts from './visualizacaoProposta/PropostaFgts'
import PropostaNovo from './visualizacaoProposta/PropostaNovo'
import PropostaPortabilidade from './visualizacaoProposta/PropostaPortabilidade'
import PropostaRefin from './visualizacaoProposta/PropostaRefin'
import PropostaRefinPortabilidade from './visualizacaoProposta/PropostaRefinPortabilidade'
import PropostaUnificadoReorganizado from './visualizacaoProposta/PropostaUnificadoReorganizado'

function DadosProposta ({ token }) {
  const [isMobile] = useMediaQuery('(max-width: 900px)')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [propostaConfirmada, setPropostaConfirmada] = useState(false)

  const { isLoading: loadingDadosProposta, data } = useGetDadosDaProposta({ token })
  const proposta = data || {}
  console.log(proposta, 'dados da proposta')

  return (
    <VStack height={'90vh'} paddingBlock={8} margin={'10px auto'} overflow={'hidden'} mt={isMobile ? '-80px' : 20} rounded={'2xl'} width={isMobile ? '90%' : '450px'} bg={'white'} paddingInline={4} alignItems={'center'} justifyContent={'space-around'}>
      {!propostaConfirmada
        ? (
            loadingDadosProposta
              ? (
              <><Text>Carregando... </Text></>
                )
              : (
              <>
                <Box left={isMobile ? '' : '47%'} top={isMobile ? 6 : 2} pos={isMobile ? 'relative' : 'absolute'} zIndex={99} >
                  <Logo />
                </Box>
                {(proposta?.propostasVinculadas[0].tipo === 'NOVO' || proposta?.propostasVinculadas[0].tipo === 'CREDITO BENEFICIO') && <PropostaNovo proposta={proposta} isMobile={isMobile} />}
                {proposta?.propostasVinculadas[0].tipo === 'REFIN' && <PropostaRefin proposta={proposta} isMobile={isMobile} />}
                {proposta?.propostasVinculadas[0].tipo === 'PORTABILIDADE' && <PropostaPortabilidade proposta={proposta} isMobile={isMobile} />}
                {proposta?.propostasVinculadas[0].tipo === 'REFIN DE PORTABILIDADE' && <PropostaRefinPortabilidade proposta={proposta} isMobile={isMobile} />}
                {proposta?.propostasVinculadas[0].tipo === 'UNIFICADO & REORGANIZADO' && <PropostaUnificadoReorganizado proposta={proposta} isMobile={isMobile} />}
                {proposta?.propostasVinculadas[0].tipo === 'FGTS' && <PropostaFgts proposta={proposta} isMobile={isMobile} />}
                <Card shadow={'none'}>
                  <Divider orientation='horizontal' />
                  <Text mt={8} textAlign={'center'} textTransform={'uppercase'} fontWeight={'semibold'} fontSize={'18px'}>Confirmação de proposta</Text>
                  <Text mb={6} textAlign={'center'} fontWeight={'semibold'} fontSize={'14px'}>Solicitamos gentilmente que confirme a solicitação dessa proposta. Agradecemos antecipadamente!</Text>
                  <HStack align={'center'} justify={'center'} mb={6}>
                    {<DialogoProposta setPropostaConfirmada={setPropostaConfirmada} token={token} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />}
                  </HStack>
                  <Text textAlign={'center'} fontWeight={'bold'} color={'gray.500'} fontSize={'10px'}>Hash Security: {token}</Text>
                </Card>
              </>
                )
          )
        : (
          <>
            <Card>
              <ConfirmacaoProposta />
            </Card>
          </>
          )}
    </VStack>
  )
}

export default DadosProposta
